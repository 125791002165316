
@import "src/assets/custom";
.scc {
  opacity: 0.1;
  fill: transparent;
  stroke: $graph-primary;
  stroke-width: 10px;
}

.greatest {
  opacity: 0.1;
  fill: $graph-primary;
  stroke: $graph-primary;
  stroke-width: 10px;
}
